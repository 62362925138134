@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3306106, 2016-11-09T04:19:25-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaLTPro-BlackCond by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/helvetica/pro-black-condensed/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 1981 - 2007 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be repro
 * Licensed pageviews: 250,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3306106
 *
 * © 2016 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/32727a");
@font-face {
  font-family: 'HelveticaProBlack';
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/32727A_0_0.eot");
  src: url("../fonts/32727A_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/32727A_0_0.woff2") format("woff2"), url("../fonts/32727A_0_0.woff") format("woff"), url("../fonts/32727A_0_0.ttf") format("truetype");
}
html {
  font-size: 75%;
  line-height: 1.33333em;
}

*::-moz-selection {
  color: #001795;
  background-color: #e1e1e1;
}
*::selection {
  color: #001795;
  background-color: #e1e1e1;
}

html, body {
  background: white;
  color: #242d27;
}

body {
  font-family: Courier, monospace;
  font-weight: 400;
  font-style: normal;
  line-height: inherit;
  -webkit-font-smoothing: subpixel-antialiased;
}

strong, b {
  font-weight: 700;
}

input, textarea, button, select, a {
  -webkit-tap-highlight-color: transparent;
}
input:focus, textarea:focus, button:focus, select:focus, a:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}
a:visited, a:active, a:hover {
  color: inherit;
}
a.active {
  cursor: default;
}
.mdzr-no-touchevents a[href^='tel:'] {
  text-decoration: none !important;
  cursor: default;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
  font-size: 1em;
}

hr {
  margin: 0;
  border: none;
  background: #b4b4b4;
  height: 1px;
  width: 100%;
  margin-top: 2.66667em;
  margin-bottom: 2.66667em;
}

.text h4, .text h5, .title, .section-title, .big-title, .super-title, .cta, .hero text, .filters button, .filters a, .filters u, .dancer-nav span {
  font-weight: 700;
  font-family: "HelveticaProBlack", Helvetica, Impact, Arial, sans-serif;
  font-kerning: auto;
  font-variant-ligatures: discretionary-ligatures, contextual;
  font-variant-numeric: diagonal-fractions;
  font-feature-settings: "case", "dlig", "frac", "kern", "liga", "ordn", "ornm";
}

.text {
  font-kerning: auto;
  font-variant-ligatures: common-ligatures, contextual;
  font-variant-numeric: oldstyle-nums, proportional-nums;
  font-feature-settings: "kern", "liga", "clig", "calt", "onum", "pnum";
}
.text p {
  font-size: 1.16667em;
  line-height: 1.42857em;
  padding-bottom: 1.14286em;
}
.text h3 {
  font-size: 1.5em;
  line-height: 1.33333em;
  margin-bottom: 0.22222em;
  font-weight: 700;
}
.text h4 {
  font-size: 1.5em;
  line-height: 1.33333em;
  margin-bottom: 0.22222em;
  font-weight: 700;
}
.text h5 {
  font-size: 2em;
  line-height: 1.16667em;
  margin-bottom: 0.66667em;
  font-weight: 700;
  text-transform: uppercase;
}
.text ul {
  list-style-type: disc;
}
.text ul, .text ol {
  padding: 0;
  margin: 0;
  margin-bottom: 1em;
}
.text ul ul, .text ul ol, .text ol ul, .text ol ol {
  margin-top: 0.28571em;
  margin-bottom: 0em;
}
.text ul {
  padding-left: 1.33333em;
}
.text ol {
  padding-left: 2em;
}
.text li {
  font-size: 1.16667em;
  line-height: 1.42857em;
  margin-bottom: 0.28571em;
}
.text li li {
  font-size: 1em;
  line-height: 1.42857em;
  margin-bottom: 0em;
}
.text a {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.mdzr-no-touchevents .text a:hover, .text a:focus, .text a:active {
  border-bottom-width: 4px;
}
.text hr {
  background: #242d27;
  height: 3px;
  width: 33.33333%;
  margin-top: 1.33333em;
  margin-bottom: 2.66667em;
}

.resources p {
  font-size: 1.33333em;
  line-height: 1.5em;
  margin-bottom: 0.5em;
}
.resources a {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.mdzr-no-touchevents .resources a:hover, .resources a:focus, .resources a:active {
  border-bottom-width: 4px;
}

.cke_editable {
  margin: 0;
  background: white;
  padding: 1.33333em;
}

@media (min-width: 32em) {
  .main-text {
    font-size: 110%;
  }
}
@media (min-width: 48em) {
  .main-text {
    font-size: 125%;
  }
}
@media only screen and (min-width: 64em) {
  .main-text {
    font-size: 150%;
  }
}
html {
  box-sizing: border-box;
}

*, *:before, *:after, input[type=search] {
  box-sizing: border-box;
}

body, html {
  position: relative;
  min-height: 100%;
  min-height: 100vh;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  body, html {
    min-height: 1024px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  body, html {
    min-height: 768px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  body, html {
    min-height: 480px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  body, html {
    min-height: 320px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  body, html {
    min-height: 568px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  body, html {
    min-height: 320px;
  }
}

body {
  margin: 0;
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}
body.no-scroll {
  overflow: hidden;
}

.load-alert {
  z-index: 10000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: red;
  color: white;
  padding: 16px 48px;
  font-size: 1.5em;
  line-height: 1.11111em;
}
.load-alert a {
  text-decoration: underline;
}

.main-container {
  display: block;
  padding-top: 6.66667em;
  flex: 1 0 auto;
}

.main-header, .main-footer {
  display: block;
  flex: 0 0 auto;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1280px;
  padding: 0 1.33333em;
  clear: both;
}
.container.full-container {
  max-width: none;
}

@media (min-width: 48em) {
  .main-container {
    padding-top: 12em;
  }

  .container {
    padding: 0 2.66667em;
  }
}
button, input, textarea {
  font-family: Courier, monospace;
  color: inherit;
  border-radius: 2px;
}
button:focus, input:focus, textarea:focus {
  outline: none;
}
button[disabled], button[readonly], input[disabled], input[readonly], textarea[disabled], textarea[readonly] {
  color: #b4b4b4;
  background-color: white;
}

textarea, input[type="text"], input[type="email"], input[type="password"], input[type="phone"], input[type="search"] {
  appearance: none;
  color: #242d27;
  min-height: 2em;
}

input, textarea {
  padding: 0 0.33333em;
  font-size: 1.33333em;
  line-height: 1.5em;
  width: 100%;
  background-color: #e1e1e1;
  border: 4px solid #e1e1e1;
}
input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #7d7d7d;
  font: 400 16px/24px Courier, monospace;
  text-align: inherit;
}
input.error, textarea.error {
  border-color: red;
  color: red;
}
input.error::placeholder, textarea.error::placeholder {
  opacity: 1;
  color: red;
  font: 400 16px/24px Courier, monospace;
  text-align: inherit;
}
input:focus, textarea:focus {
  background-color: white;
  border-color: white;
}

button, input[type="submit"] {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  appearance: none;
}

input[type="submit"] {
  display: inline-block;
  padding-bottom: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
}
.mdzr-no-touchevents input[type="submit"]:hover, input[type="submit"]:focus {
  padding-bottom: 0;
  border-bottom-width: 2px;
}

input[type="radio"], input[type="checkbox"] {
  border: 1px solid #242d27;
}

input[type="color"] {
  padding: 0;
  width: 2.66667em;
  height: 2.66667em;
}

textarea {
  resize: vertical;
}

.touchbot {
  display: none;
}

.input.inline input, .input.radios label, .input.checkboxes label, .input.radios input, .input.checkboxes input, button, input[type='submit'] {
  display: inline-block;
  width: auto;
}

.input.radios label, .input.checkboxes label {
  font-weight: normal;
  margin-right: 1.33333em;
}

.input, fieldset {
  position: relative;
  margin-bottom: 1.33333em;
}

fieldset {
  padding-top: 1.33333em;
  border: 1px solid #b4b4b4;
}

legend {
  font-weight: 700;
  text-transform: uppercase;
}

label {
  display: block;
  font-size: 1.16667em;
  line-height: 1.42857em;
  margin-bottom: 0.28571em;
  font-weight: 700;
}
label .msg {
  display: inline;
  font-weight: 400;
}

.error input, .error .msg, .error p {
  color: red;
}
.error input {
  border-color: red;
}
.error input::placeholder {
  opacity: 1;
  color: red;
  font: 400 16px/24px Courier, monospace;
  text-align: inherit;
}

.main-header {
  width: 100%;
  position: relative;
  height: 0;
}

.logo {
  position: absolute;
  display: block;
  left: 1.33333em;
  top: 1.33333em;
  z-index: 20;
}
.logo img {
  display: block;
  width: 176px;
  height: 48px;
}
.logo .logo-alt, .logo span {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.menu-btn {
  display: block;
  position: fixed;
  top: 1.33333em;
  right: 1.33333em;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: none;
  appearance: none;
  background-color: transparent;
  padding: 0;
  z-index: 2000;
}
.mdzr-no-inlinesvg .menu-btn:after {
  display: block;
  content: "menu";
  text-indent: -99999px;
  text-indent: -9999rem;
  overflow: hidden;
  text-align: left;
  background-image: url("../img/sprites.png?=1544619754206");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .mdzr-no-inlinesvg .menu-btn:after {
    background-image: url("../img/sprites_2x.png?=1544619754206");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: 98px 98px;
  }
}
.mdzr-no-inlinesvg .menu-btn svg {
  display: none;
}
.menu-btn svg {
  display: block;
  width: 100%;
  height: 100%;
}
.menu-btn svg .slice {
  transform: translateZ(0);
  transition: transform 0.2s ease-in-out 75ms;
  fill: white;
}
.menu-btn svg .circle-1 {
  fill: white;
  transform-origin: 24px 24px;
  transform: scale(0, 0);
}
.menu-btn svg .circle-2 {
  fill: #242d27;
  transform-origin: 24px 24px;
  transform: scale(1, 1);
  mix-blend-mode: darken;
}
.menu-btn svg .slice1 {
  transform-origin: 24px 24px;
  transform: rotate(45deg) translate(0, 8px);
}
.menu-btn svg .slice2 {
  transform: rotate(-45deg);
  transform-origin: 24px 24px;
}
.menu-btn svg .slice3 {
  transform-origin: 24px 24px;
  transform: rotate(45deg) translate(0, -8px);
}
.mdzr-no-touchevents .menu-btn svg:hover .slice, .menu-btn svg:focus .slice {
  fill: #242d27;
}
.mdzr-no-touchevents .menu-btn svg:hover .circle-1, .menu-btn svg:focus .circle-1 {
  transform: scale(1, 1);
}
.mdzr-no-touchevents .menu-btn svg:hover .circle-2, .menu-btn svg:focus .circle-2 {
  transform: scale(0, 0);
}
.menu-btn.idle .slice {
  transform: rotate(0);
}
.mdzr-no-inlinesvg .menu-btn.idle:after {
  background-image: url("../img/sprites.png?=1544619754206");
  background-position: -50px 0px;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .mdzr-no-inlinesvg .menu-btn.idle:after {
    background-image: url("../img/sprites_2x.png?=1544619754206");
    background-position: -50px 0px;
    background-repeat: no-repeat;
    background-size: 98px 98px;
  }
}

.langs {
  margin-top: 0.66667em;
  padding-bottom: 3.33333em;
  font-size: 2em;
  line-height: 1.33333em;
}
.langs span, .langs a {
  display: inline-block;
  text-transform: uppercase;
  color: #242d27;
}
.langs span {
  border-bottom: 4px solid #242d27;
}
.mdzr-no-touchevents .langs a:hover, .langs a:focus {
  color: #7d7d7d;
  border-bottom: 4px solid #7d7d7d;
}

@media (min-width: 48em) {
  .logo {
    top: 4em;
    left: 2.66667em;
  }
  .logo img {
    width: 249px;
    height: 68px;
  }
}
.main-footer {
  background-color: #b4b4b4;
  color: white;
}
.main-footer a {
  padding-bottom: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
}
.mdzr-no-touchevents .main-footer a:hover, .main-footer a:focus {
  padding-bottom: 0;
  border-bottom-width: 2px;
}

.f-container {
  padding-bottom: 2.66667em;
  -webkit-font-smoothing: antialiased;
}

.f-row {
  padding-top: 2.66667em;
}
.f-row .input {
  max-width: 21.33333em;
}

.f-statement {
  -webkit-font-smoothing: antialiased;
}

.f-follow li {
  display: inline-block;
  margin-right: 0.66667em;
  margin-bottom: 0.66667em;
}
.f-follow a {
  display: inline-block;
  font-size: 1.33333em;
  line-height: 1.5em;
}

.f-credits {
  color: #e1e1e1;
  padding: 1.33333em 0;
  background-color: #242d27;
}

.footer-news {
  clear: both;
  border-top: 4px solid #242d27;
  margin-top: 2.66667em;
  padding-top: 2.66667em;
  padding-bottom: 2.66667em;
}

@media (min-width: 32em) {
  .f-container {
    position: relative;
  }
  .f-container:before, .f-container:after {
    content: " ";
    display: table;
  }
  .f-container:after {
    clear: both;
  }

  .f-row {
    width: 50%;
    padding-right: 1.33333em;
  }

  .f-follow {
    width: 100%;
  }

  .f-pro {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 0;
    padding-left: 1.33333em;
  }
}
@media (min-width: 48em) {
  .f-row {
    float: left;
    width: 33.33333%;
    width: calc(100%/3);
    padding: 2.66667em 1.33333em 0 1.33333em;
  }

  .f-newsletter {
    padding-left: 0;
  }

  .f-pro {
    padding-left: 2em;
  }

  .f-follow ul {
    margin-top: -16px;
  }
  .f-follow li {
    display: block;
    margin-bottom: 4px;
  }
}
.menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  height: 100vh;
  height: 100vh;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition-property: opacity, visibility, z-index;
  transition-duration: 0.1s;
  transition-delay: 0.6s;
  transition-timing-function: linear;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .menu-wrapper {
    height: 1024px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  .menu-wrapper {
    height: 768px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  .menu-wrapper {
    height: 480px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  .menu-wrapper {
    height: 320px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  .menu-wrapper {
    height: 568px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  .menu-wrapper {
    height: 320px;
  }
}
.unfold .menu-wrapper {
  opacity: 1;
  z-index: 1100;
  transition-delay: 0.1s;
  visibility: visible;
}
.menu-wrapper .container {
  height: 100%;
}

.menu-container {
  background-color: white;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  transition-property: height;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0.1s;
  height: 0;
  padding: 0 1.33333em;
}
.unfold .menu-container {
  height: 100vh;
  overflow: auto;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .unfold .menu-container {
    height: 1024px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  .unfold .menu-container {
    height: 768px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  .unfold .menu-container {
    height: 480px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  .unfold .menu-container {
    height: 320px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  .unfold .menu-container {
    height: 568px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  .unfold .menu-container {
    height: 320px;
  }
}

.menu {
  display: block;
  text-transform: uppercase;
}
.menu a {
  display: block;
  padding: 0.33333em 0 0.66667em 0;
  color: #242d27;
}
.mdzr-no-touchevents .menu a:hover span, .menu a:active span, .menu a:focus span, .menu a.focus span {
  color: #7d7d7d;
}
.mdzr-no-touchevents .menu a:hover.active, .menu a:active.active, .menu a:focus.active, .menu a.focus.active {
  cursor: default;
}
.mdzr-no-touchevents .menu a:hover.active span, .menu a:active.active span, .menu a:focus.active span, .menu a.focus.active span {
  color: #242d27;
  border-bottom-color: #242d27;
}
.menu a.active span {
  border-bottom-color: #242d27;
}
.menu > a {
  position: relative;
  z-index: 100;
}
.menu span {
  display: inline-block;
  font-family: "HelveticaProBlack", Helvetica, Impact, Arial, sans-serif;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}

.level-1 {
  width: 100%;
  padding-top: 6.66667em;
}
.level-1 span {
  font-size: 6em;
  line-height: 0.88889em;
}

.level-2 {
  display: none;
}
.level-2.unfold {
  display: block;
}
.level-2 a {
  height: auto;
  padding-bottom: 1.13333em;
}
.level-2 span {
  font-size: 2.66667em;
  line-height: 1em;
}

@media (min-width: 32em) {
  .menu > a {
    width: 21.33333em;
  }

  .level-2 {
    display: block !important;
    z-index: 10;
    position: absolute;
    top: 6.66667em;
    left: 22.66667em;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.25s;
    transition-timing-function: linear;
    transition-delay: 0.15s;
  }
  .level-2.unfold {
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 48em) {
  .menu-container {
    padding: 0 2.66667em;
  }

  .level-1 {
    padding-top: 13.33333em;
  }

  .level-2 {
    top: 13.33333em;
    left: 25.33333em;
  }
}
@media only screen and (min-width: 64em) {
  .level-2 a {
    padding-bottom: 0.46667em;
  }
  .level-2 span {
    font-size: 3.33333em;
    line-height: 1em;
  }
}
.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

img {
  border: none;
}

figure {
  position: relative;
  width: 100%;
  margin: 0;
  margin-bottom: 1.33333em;
}
figure img {
  display: block;
  width: 100%;
  height: auto;
}
figure figcaption {
  margin-top: 0.33333em;
}
figure.ratio-fig img {
  max-width: 100%;
  max-height: 21.33333em;
  width: auto;
  height: auto;
}
figure.ratio-medium {
  max-width: 48em;
}
figure.ratio-medium img {
  max-height: 32em;
}
figure.ratio-large {
  max-width: 80em;
}
figure.ratio-large img {
  max-height: 53.33333em;
}
figure.ratio-box {
  height: 0;
  padding-bottom: 66.66667%;
  padding-bottom: calc(100%/1.5);
}
figure.ratio-box img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.video-frame {
  position: relative;
  padding: 0 0 56.25% 0;
  height: 0;
}
.video-frame object, .video-frame embed, .video-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.title {
  font-size: 2em;
  line-height: 1em;
}

.section-title {
  font-size: 2em;
  line-height: 1.16667em;
  margin-bottom: 0.66667em;
  text-transform: uppercase;
}

.big-title span {
  padding-right: 0.16667em;
  font-size: 2.33333em;
  line-height: 1.14286em;
}
.big-title sup {
  font-family: Courier, monospace;
  font-size: 1.16667em;
  line-height: 1.14286em;
  vertical-align: top;
  top: auto;
}

.super-title {
  font-size: 2.5em;
  line-height: 1.33333em;
  line-height: 1;
  text-transform: uppercase;
}
.super-title.fs-3 {
  font-size: 2.5em;
  line-height: 1.33333em;
  line-height: 1;
}
.super-title.fs-2, .super-title.fs-1 {
  font-size: 5em;
  line-height: 1.06667em;
  line-height: 1;
}

.list li {
  margin-bottom: 1.33333em;
  text-transform: uppercase;
}
.list a {
  opacity: 1;
  transition: opacity 0.2s linear;
}
.mdzr-no-touchevents .list a:hover, .list a:focus {
  opacity: 0.5;
}

.cta {
  text-transform: uppercase;
  display: inline-block;
  font-size: 2em;
  line-height: 1.16667em;
  padding-bottom: 2px;
  border-bottom: 2px solid #242d27;
}
.mdzr-no-touchevents .cta:hover, .cta:focus {
  padding-bottom: 0;
  border-bottom-width: 4px;
}
.cta:after {
  content: "+";
  margin-left: 0.33333em;
}

.breadcrumb {
  font-size: 1.16667em;
  line-height: 1.42857em;
  margin-bottom: 2.28571em;
  font-weight: 700;
  text-transform: lowercase;
}
.breadcrumb a {
  text-decoration: underline;
}
.mdzr-no-touchevents .breadcrumb a:hover, .breadcrumb a:focus {
  text-decoration: none;
}

.pagination {
  width: 100%;
  clear: both;
  margin-top: 4em;
  margin-bottom: 1.33333em;
}
.pagination li {
  display: inline-block;
  margin-right: 0.66667em;
}
.pagination a, .pagination span {
  display: block;
  font-size: 1.16667em;
  line-height: 1.42857em;
  font-weight: 700;
}
.pagination span {
  color: #b4b4b4;
}
.pagination a {
  border-bottom: 4px solid transparent;
}
.mdzr-no-touchevents .pagination a:hover, .pagination a:focus {
  border-bottom-color: #242d27;
}

@media (min-width: 32em) {
  .super-title {
    font-size: 6vw;
    line-height: 1;
  }
  .super-title.fs-3 {
    font-size: 8vw;
    line-height: 1;
  }
  .super-title.fs-2, .super-title.fs-1 {
    font-size: 12vw;
    line-height: 1;
  }

  .big-title span {
    font-size: 3.33333em;
    line-height: 1.2em;
  }
}
@media only screen and (min-width: 64em) {
  .super-title {
    font-size: 5em;
    line-height: 1.06667em;
    line-height: 1;
  }
  .super-title.fs-3 {
    font-size: 6.66667em;
    line-height: 1.1em;
    line-height: 1;
  }
  .super-title.fs-2, .super-title.fs-1 {
    font-size: 10em;
    line-height: 1.06667em;
    line-height: 1;
  }
}
.inner-grid {
  font-size: 0;
  min-height: 96px;
}
.inner-grid .item {
  font-size: 12px;
}

.masonry .inner-grid {
  background-image: url(../img/spinner.gif);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: height 0.25s linear;
}
.masonry .inner-grid.loaded {
  background: none;
}
.masonry .item {
  opacity: 0;
  transition: opacity 0.25s linear;
}
.masonry .item.loaded {
  opacity: 1;
}

.item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 2.66667em;
}
.item a {
  display: block;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s linear 0.1s;
}
.mdzr-no-touchevents .item a:hover, .item a:focus {
  opacity: 0.5;
}
.item address {
  margin-top: 0.33333em;
}
.item-prod .title {
  text-transform: uppercase;
}
.item-prod .datetime, .item-prod p {
  display: block;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.11111em;
  padding-bottom: 0.44444em;
}
.item-prod a:after {
  content: "";
  display: block;
  background: #242d27;
  height: 2px;
  width: 16em;
  margin-top: 1em;
}
.item-news p {
  font-size: 1.5em;
  line-height: 1.33333em;
}
.item-news .inner:after {
  content: "";
  display: block;
  margin-top: 1em;
  display: block;
  background-image: url("../img/dash_2x.png");
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 16px 2px;
  height: 2px;
  width: 184px;
}
.mdzr-no-backgroundsize .item-news .inner:after {
  background-image: url("../img/dash.png");
}
.item-news-large .inner:after {
  height: 8px;
  background: #242d27;
}
.item-publication {
  border-bottom: 4px dashed #242d27;
  margin-bottom: 1.33333em;
}
.item-publication figure {
  position: relative;
  width: 24em;
}
.item-publication:last-child {
  border-bottom: none;
  padding-bottom: 0em;
}
.item-dancer {
  padding-top: 1.33333em;
  margin-bottom: 1.33333em;
  overflow-x: visible;
}
.item-dancer figure {
  background-color: #e1e1e1;
}
.item-dancer p {
  font-size: 1.16667em;
  line-height: 1.14286em;
  margin-top: 0.57143em;
}

.outer {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  padding: 1.33333em;
}
.mdzr-no-csstransforms .outer {
  height: 50%;
}
.mdzr-no-csstransforms .outer .inner {
  position: absolute;
  width: 100%;
  top: -25%;
  height: 50%;
}

@media (min-width: 32em) {
  .inner-grid {
    position: relative;
    margin-right: -16px;
    margin-left: -16px;
  }

  .grid .item {
    margin-top: 0em;
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .item-news-large .big-title span {
    font-size: 2.5em;
    line-height: 1.2em;
  }
}
@media (min-width: 48em) {
  .grid-3 .item, .grid-4 .item, .grid-5 .item {
    width: 33.33333%;
    width: calc(100%/3);
  }

  .item-publication a {
    padding-left: 25.33333em;
    min-height: 18.66667em;
  }
  .item-publication figure {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 0em;
  }
}
@media only screen and (min-width: 64em) {
  .grid-4 .item, .grid-5 .item {
    width: 25%;
  }

  .item-news-large .big-title span {
    font-size: 3.5em;
    line-height: 1.14286em;
  }
}
@media only screen and (min-width: 80em) {
  .grid-5 .item {
    width: 20%;
  }

  .grid-medias .item {
    width: 40%;
  }
}
.hero {
  position: relative;
  width: 100%;
  color: white;
  text-align: left;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -6.66667em;
  z-index: 300;
  margin-bottom: 1.33333em;
  height: 50%;
  height: 50vh;
  height: 50vh;
  min-height: 42.66667em;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .hero {
    height: 512px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  .hero {
    height: 384px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  .hero {
    height: 240px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  .hero {
    height: 160px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  .hero {
    height: 284px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  .hero {
    height: 160px;
  }
}
.hero figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0em;
  overflow: hidden;
  background: #e1e1e1;
}
.hero figure img, .hero figure video {
  mix-blend-mode: multiply;
}
.mdzr-no-backgroundblendmode .hero figure {
  background: #b4b4b4;
}
.mdzr-no-backgroundblendmode .hero figure img, .mdzr-no-backgroundblendmode .hero figure video {
  opacity: 0.95;
}
.hero .super-title {
  display: none;
}
.hero #heroSvg {
  width: 100%;
  height: auto;
  max-height: 60%;
  line-height: 36px;
}
.hero text {
  font-size: 32px;
  line-height: 36px;
  height: 36px;
  fill: white;
  text-transform: uppercase;
  transform-origin: 0 0;
}
.hero p {
  font-size: 1.5em;
  line-height: 1.33333em;
  margin-bottom: 0.88889em;
}
.hero p a {
  border-bottom: 2px solid white;
  white-space: nowrap;
}
.mdzr-no-touchevents .hero p a:hover, .hero p a:focus {
  border-bottom-width: 4px;
}
.hero.color-1 {
  background-color: #001795;
}
.hero.color-2 {
  background-color: #fc5600;
}
.hero.color-3 {
  background-color: #ff64a3;
}
.hero.color-4 {
  background-color: #a9a490;
}
.hero.color-5 {
  background-color: #242d27;
}

.hero-logo {
  z-index: 30;
}

.hero-img {
  height: 0;
  min-height: 0;
  padding-bottom: 125%;
  overflow: hidden;
}

.hero-video figure img, .hero-video figure video {
  position: relative;
  display: inline-block;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}
.mdzr-no-objectfit .hero-video figure img, .mdzr-no-objectfit .hero-video figure video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
}
.hero-video figure .IIV::-webkit-media-controls-play-button, .hero-video figure video::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}
.hero-video figure img {
  display: none;
}
.mdzr-no-video .hero-video figure video {
  display: none;
}
.mdzr-no-video .hero-video figure img {
  display: block;
}

.hero-container {
  position: absolute;
  top: 6.66667em;
  bottom: 2.66667em;
  left: 0;
  right: 0;
  -webkit-font-smoothing: antialiased;
}

.inner-container {
  max-width: 64em;
}

.mdzr-no-inlinesvg .hero .super-title {
  display: block;
}
.mdzr-no-inlinesvg .hero svg {
  display: none;
}

#home_productions, #home_news {
  padding-top: 1.33333em;
  margin-bottom: 4em;
}

#home_news {
  border-top: 2px solid #242d27;
  padding-top: 1.33333em;
}
#home_news address {
  display: none;
}

@media (min-width: 24em) {
  .hero-img {
    padding-bottom: 100%;
  }
}
@media (min-width: 32em) {
  .hero-img {
    padding-bottom: 66.66667%;
    padding-bottom: calc(100% / 1.5);
  }
}
@media (min-width: 48em) {
  .hero-img {
    padding-bottom: 50%;
  }

  .hero {
    margin-top: -12em;
  }
  .hero p {
    font-size: 2em;
    line-height: 1.33333em;
    margin-bottom: 0.66667em;
  }

  .hero-container {
    top: 12em;
  }

  .home-container {
    position: relative;
  }
  .home-container:before, .home-container:after {
    content: " ";
    display: table;
  }
  .home-container:after {
    clear: both;
  }
  .home-container section {
    float: left;
  }

  #home_productions {
    width: 33.33333%;
    width: calc(100%/3);
    padding-right: 1.33333em;
  }

  #home_news {
    width: 66.66667%;
    width: calc(100%/1.5);
    padding-left: 1.33333em;
    border-top: none;
  }
}
@media only screen and (min-width: 64em) {
  .hero-img {
    padding-bottom: 37.5%;
  }

  .hero-title {
    font-size: 6.5em;
  }
  .hero-title.fs-4 {
    font-size: 8.5em;
  }
  .hero-title.fs-3 {
    font-size: 10em;
  }
  .hero-title.fs-2 {
    font-size: 15em;
  }
  .hero-title.fs-1 {
    font-size: 20em;
  }
}
.filters {
  color: #242d27;
}
.filters:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: white;
  transition-property: opacity, visibility, z-index;
  transition-duration: 0.25s;
  transition-timing-function: linear;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.filters.blur:before {
  opacity: 0.95;
  visibility: visible;
  z-index: 190;
}
.filters li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}
.filters .nav-2 {
  position: absolute;
  top: 100%;
  left: 0;
  transition-property: opacity, visibility, z-index;
  transition-duration: 0.25s;
  transition-timing-function: linear;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.filters .nav-2 li {
  display: block;
}
.filters .nav-2.unfold {
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}
.filters .nav-2.nav-cols {
  min-width: 16em;
  margin-left: -0.66667em;
  margin-right: -0.66667em;
}
.filters .nav-2.nav-cols:before, .filters .nav-2.nav-cols:after {
  content: " ";
  display: table;
}
.filters .nav-2.nav-cols:after {
  clear: both;
}
.filters .nav-2.nav-cols li {
  float: left;
  min-width: 5.33333em;
  padding: 0 0.66667em 0.66667em 0.66667em;
}
.filters .nav-2.nav-cols li.full-width {
  width: 100%;
}
.filters button, .filters a, .filters u {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
}
.filters button {
  max-width: 100%;
  display: block;
  position: relative;
  appearance: none;
  background: none;
  overflow: visible;
  text-align: left;
  border: none;
  padding: 0;
  font-size: 3em;
  line-height: 1.33333em;
  z-index: 1;
  transition: z-index 0.25s linear;
}
.filters button span {
  display: inline;
  transition: opacity 0.25s linear;
  opacity: 1;
}
.filters button:after {
  content: "▼";
  position: relative;
  font-size: 0.5em;
  line-height: 1.77778em;
  margin: 0 8px 0 4px;
  text-indent: -99999px;
  text-indent: -9999rem;
  overflow: hidden;
  text-align: left;
  display: inline-block;
  background-image: url("../img/sprites.png?=1544619754206");
  background-position: -84px -50px;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .filters button:after {
    background-image: url("../img/sprites_2x.png?=1544619754206");
    background-position: -84px -50px;
    background-repeat: no-repeat;
    background-size: 98px 98px;
  }
}
.filters button.active {
  z-index: 200;
}
.filters button.active span {
  opacity: 0.1;
}
.filters a, .filters u {
  font-size: 2em;
  line-height: 1em;
  padding-top: 0.33333em;
  border-bottom: 4px solid transparent;
}
.mdzr-no-touchevents .filters a:hover, .filters a:focus {
  color: #7d7d7d;
}
.filters u {
  border-bottom-color: #242d27;
}

.subfilters {
  display: none;
  margin-bottom: 1.33333em;
}
.mdzr-js .subfilters {
  display: block;
}
.subfilters .subfilter {
  position: relative;
  display: inline-block;
  border-bottom: #242d27 solid 1px;
  padding: 0;
  margin-right: 0.66667em;
  margin-bottom: 1.33333em;
}
.subfilters .subfilter:after {
  position: absolute;
  right: 0.33333em;
  bottom: 1.33333em;
  z-index: 1;
  content: "▼";
  font-size: 0.83333em;
  line-height: 1.6em;
  display: inline-block;
  text-indent: -99999px;
  text-indent: -9999rem;
  overflow: hidden;
  text-align: left;
  background-image: url("../img/sprites.png?=1544619754206");
  background-position: -84px -66px;
  background-repeat: no-repeat;
  width: 7px;
  height: 7px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .subfilters .subfilter:after {
    background-image: url("../img/sprites_2x.png?=1544619754206");
    background-position: -84px -66px;
    background-repeat: no-repeat;
    background-size: 98px 98px;
  }
}
.subfilters select {
  position: relative;
  z-index: 10;
  appearance: none;
  border: none;
  border-radius: 0;
  background: none;
  font-size: 1.33333em;
  line-height: 1.5em;
  padding: 0.33333em 1.33333em 0.33333em 0;
}
.subfilters select::-ms-expand {
  display: none;
}
.subfilters select:focus {
  outline: none;
}
.subfilters option {
  white-space: normal;
}

@media (min-width: 48em) {
  .filters {
    position: relative;
    margin-top: -7.66667em;
    padding-bottom: 1.33333em;
    min-height: 16em;
  }
  .filters .filter-1, .filters .filter-1 ul {
    padding-left: 22.66667em;
  }
  .filters .nav-2.nav-cols {
    min-width: 24em;
  }
  .filters button {
    font-size: 5.16667em;
    line-height: 1.41935em;
  }
  .filters button:after {
    background-image: url("../img/sprites.png?=1544619754206");
    background-position: -54px -50px;
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
  }
}
@media only screen and (min-width: 48em) and (min-resolution: 2dppx), (min-width: 48em) and (-webkit-min-device-pixel-ratio: 2) {
  .filters button:after {
    background-image: url("../img/sprites_2x.png?=1544619754206");
    background-position: -54px -50px;
    background-repeat: no-repeat;
    background-size: 98px 98px;
  }
}
@media (min-width: 48em) {
  .filters a, .filters u {
    font-size: 3.33333em;
    line-height: 1.2em;
    padding-top: 0.2em;
  }
}
.article-header {
  margin-bottom: 1.33333em;
  max-width: 80em;
}

.main-text, .notes {
  max-width: 960px;
}

.float-fig {
  max-width: 32em;
}

.news-detail {
  max-width: 61.33333em;
}

.credits {
  margin-bottom: 1.33333em;
}

.gallery-ratio figure {
  margin-bottom: 0em;
}

.notes {
  padding-top: 1.33333em;
  margin-bottom: 2.66667em;
}

.sponsors {
  font-size: 0;
  margin: 0 -16px 16px -16px;
}

.sponsor {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  padding: 1.33333em;
}
.sponsor a {
  border: none;
}
.sponsor img {
  display: block;
  position: relative;
  text-align: center;
  width: auto;
  height: auto;
  max-width: 10.66667em;
  max-height: 6.66667em;
}

@media (min-width: 24em) {
  .gallery-natural {
    margin-right: -1.33333em;
  }
  .gallery-natural figure {
    display: inline-block;
    margin-right: 1.33333em;
    max-width: 100%;
    width: auto;
  }
  .gallery-natural img {
    max-height: 32em;
    max-width: 100%;
    width: auto;
  }
}
@media (min-width: 32em) {
  .hero.bg-img figure {
    padding-bottom: 100%/1.5;
    padding-bottom: calc(100% / 1.5);
  }

  .credits:before, .credits:after {
    content: " ";
    display: table;
  }
  .credits:after {
    clear: both;
  }

  .credit-2 {
    float: left;
    width: 50%;
  }
  .credit-2:nth-child(odd) {
    padding-right: 1.33333em;
  }
  .credit-2:nth-child(even) {
    padding-left: 1.33333em;
  }

  .gallery figcaption {
    display: none;
  }

  .gallery-natural img {
    max-height: 8em;
  }
}
@media (min-width: 48em) {
  .gallery-natural img {
    max-height: 16em;
  }

  .float-fig {
    float: left;
    margin: 0.66667em 2.66667em 2.66667em 0;
  }

  .float-right {
    float: right;
    margin: 0.66667em 0 2.66667em 2.66667em;
  }
}
.dancer-nav {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #242d27;
  color: white;
  z-index: 200;
  display: none;
}
.dancer-nav li {
  display: none;
}
.dancer-nav li.active {
  display: block;
}
.dancer-nav a {
  display: block;
  padding: 1.33333em;
}
.dancer-nav span {
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}

.item-agenda {
  margin-bottom: 1.33333em;
}
.item-agenda .title {
  display: inline;
  font-size: 1.33333em;
  line-height: 1.25em;
  text-transform: uppercase;
}
.item-agenda .title a {
  border-bottom: 2px solid #242d27;
}
.item-agenda .datetime {
  display: block;
  font-weight: 700;
  font-size: 1.33333em;
  line-height: 1.25em;
}
.item-agenda p {
  display: block;
  font-size: 1.16667em;
  line-height: 1.42857em;
}
.item-agenda a span {
  border-bottom: 1px solid #242d27;
}
.mdzr-no-touchevents .item-agenda a span:hover, .item-agenda a:focus span {
  border-bottom-width: 2px;
}
.item-agenda ul {
  list-style-type: disc;
  padding-left: 1.33333em;
}
.item-agenda li {
  position: relative;
  margin-top: 0.33333em;
}
.item-agenda li time {
  font-weight: 700;
}

.files {
  margin-bottom: 2.66667em;
}
.files li {
  margin-bottom: 0.66667em;
  display: block;
}
.files a {
  display: inline-block;
  font-size: 1.33333em;
  line-height: 1.5em;
}
.files a u {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.mdzr-no-touchevents .files a:hover u, .files a:focus u, .files a:active u {
  border-bottom-width: 4px;
}
.files a span {
  white-space: nowrap;
}

.pro-login {
  position: relative;
  margin-left: -1.33333em;
  margin-right: -1.33333em;
  margin-bottom: 5.33333em;
  background: #b4b4b4;
  padding: 1.33333em;
  color: white;
  -webkit-font-smoothing: antialiased;
}
.pro-login .input {
  max-width: 21.33333em;
}

@media (min-width: 24em) {
  .item-dancer p {
    font-size: 1.33333em;
    line-height: 1.25em;
    margin-top: 0.5em;
  }
}
@media (min-width: 32em) {
  .dancer-nav {
    display: none !important;
  }

  .item-dancer {
    opacity: 1;
    transition: opacity 0.2s linear 0.1s;
    cursor: pointer;
  }
  #dancer_grid:hover .item-dancer:hover, #dancer_grid:hover .item-dancer.active, #dancer_grid.active .item-dancer.active {
    opacity: 1;
  }
  #dancer_grid:hover .item-dancer, #dancer_grid.active .item-dancer {
    opacity: 0.25;
  }
  .item-dancer .item-top {
    position: relative;
    padding-bottom: 0.66667em;
  }
  .item-dancer .item-bottom {
    display: none;
    position: absolute;
    background: white;
    width: 200%;
    left: 0;
    top: auto;
    padding: 0 1.33333em 1.33333em 1.33333em;
    z-index: 100;
  }
  .item-dancer .item-bottom p {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.25s;
    transition-delay: 0s;
  }
  .item-dancer:nth-child(even) .item-bottom {
    left: -100%;
  }
  .item-dancer.active .item-top:after {
    display: block;
  }
  .item-dancer.active .item-bottom {
    display: block;
  }
  .item-dancer.active .item-bottom p {
    opacity: 1;
    transition-delay: 0.25s;
  }

  .item-agenda .datetime, .item-agenda p {
    display: inline;
  }
  .item-agenda li {
    padding-left: 7.66667em;
    text-indent: -7.66667em;
  }
}
@media (min-width: 48em) {
  .item-dancer .item-bottom {
    width: 200%;
  }
  .item-dancer:nth-child(3n+1) .item-bottom {
    left: 0;
  }
  .item-dancer:nth-child(3n+2) .item-bottom, .item-dancer:nth-child(3n+3) .item-bottom {
    left: -100%;
  }

  .pro-login {
    width: 50%;
  }
}
@media only screen and (min-width: 64em) {
  .item-dancer .item-bottom {
    width: 300%;
  }
  .item-dancer:nth-child(4n+1) .item-bottom {
    left: 0;
  }
  .item-dancer:nth-child(4n+2) .item-bottom, .item-dancer:nth-child(4n+3) .item-bottom {
    left: -100%;
  }
  .item-dancer:nth-child(4n+4) .item-bottom {
    left: -200%;
  }
}
a.fancybox {
  display: block;
}

.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  text-shadow: none;
  background: transparent;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
  background: transparent url("../img/alpha.png");
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  margin: 0;
  padding: 0 1.33333em 5.33333em;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading {
  position: fixed;
  bottom: 1.33333em;
  left: 50%;
  margin-left: -24px;
  z-index: 8060;
}

#fancybox-loading div {
  width: 48px;
  height: 48px;
  background: url("../img/spinner.gif") center center no-repeat;
}

.fancybox-close {
  position: fixed;
  top: 1.33333em;
  right: 1.33333em;
  cursor: pointer;
  z-index: 8040;
  background-image: url("../img/sprites.png?=1544619754206");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .fancybox-close {
    background-image: url("../img/sprites_2x.png?=1544619754206");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: 98px 98px;
  }
}

.fancybox-nav {
  position: fixed;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../img/alpha.png");
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  z-index: 8040;
  transition: opacity 500ms;
  opacity: 0.4;
}
.fancybox-type-ajax .fancybox-nav {
  width: 60px;
}
.fancybox-nav:hover {
  opacity: 1;
}

.mdzr-touchevents .fancybox-nav {
  top: 25%;
  height: 50%;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 25px;
  height: 48px;
  margin-top: -24px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-prev span {
  left: 1.33333em;
  text-indent: -99999px;
  text-indent: -9999rem;
  overflow: hidden;
  text-align: left;
  background-image: url("../img/sprites.png?=1544619754206");
  background-position: -27px -50px;
  background-repeat: no-repeat;
  width: 25px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .fancybox-prev span {
    background-image: url("../img/sprites_2x.png?=1544619754206");
    background-position: -27px -50px;
    background-repeat: no-repeat;
    background-size: 98px 98px;
  }
}

.fancybox-next span {
  right: 1.33333em;
  text-indent: -99999px;
  text-indent: -9999rem;
  overflow: hidden;
  text-align: left;
  background-image: url("../img/sprites.png?=1544619754206");
  background-position: 0px -50px;
  background-repeat: no-repeat;
  width: 25px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .fancybox-next span {
    background-image: url("../img/sprites_2x.png?=1544619754206");
    background-position: 0px -50px;
    background-repeat: no-repeat;
    background-size: 98px 98px;
  }
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

.fancybox-lock {
  overflow: hidden;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background-color: white;
  background-image: url(../img/svg/logo-rosas.svg);
  background-size: 234px 64px;
  background-position: center center;
  background-repeat: no-repeat;
}
.mdzr-no-svg .fancybox-overlay {
  background-image: url(../img/svg/logo-rosas_234x64.png);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

.fancybox-title {
  visibility: hidden;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  position: absolute;
  left: 0;
  bottom: -4em;
  width: 100%;
  padding: 0.66667em 1.33333em;
  text-align: center;
  color: #5a5a5a;
  min-height: 4em;
}
.fancybox-title-inside-wrap h4, .fancybox-title-inside-wrap p {
  display: inline;
  font-size: 1.16667em;
  line-height: 1.14286em;
}
.fancybox-title-inside-wrap h4 {
  color: #242d27;
  font-weight: 500;
}
.fancybox-title-inside-wrap p {
  margin-left: 0.33333em;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

.cc-window {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 4em;
  background: #242d27;
  color: white;
  padding: 1.33333em;
  opacity: 1;
  z-index: 1000;
  visibility: visible;
  transition: opacity 0.25s linear, visibility 0.25s linear, z-index 0.25s linear;
}
.cc-window.cc-invisible {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}

.cc-message {
  display: block;
  font-size: 1.16667em;
  line-height: 1.42857em;
}
.cc-message a {
  text-decoration: underline;
  white-space: nowrap;
}
.cc-message a:hover, .cc-message a:focus {
  text-decoration: none;
}

.cc-compliance {
  margin-top: 1.33333em;
}

.cc-dismiss {
  display: block;
  padding: 4px 16px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  text-align: center;
  width: 100%;
  font-size: 1.16667em;
  line-height: 1.42857em;
}
.cc-dismiss:hover, .cc-dismiss:focus {
  background-color: white;
  color: #242d27;
  text-decoration: none;
}

@media (min-width: 32em) {
  .cc-window {
    padding-right: 6.66667em;
  }

  .cc-compliance {
    position: absolute;
    top: 50%;
    right: 1.33333em;
    margin-top: -1.33333em;
  }

  .cc-dismiss {
    width: auto;
  }
}
@media (min-width: 48em) {
  .cc-window {
    padding-left: 2.66667em;
    padding-right: 9.33333em;
  }

  .cc-compliance {
    right: 2.66667em;
  }
}

/*# sourceMappingURL=main.css.map */
